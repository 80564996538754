import React, {Component} from "react";
import {Link} from "@reach/router";

class LinkComponent extends Component {
    render() {
        const {to, className, style, children} = this.props;
        const interfaceView = (
            <Link to={to} className={className} style={style}>
                {children}
            </Link>
        );

        return <>{interfaceView}</>;
    }
}

export default LinkComponent;
